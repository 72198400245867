.navbar {
    background-color: black;
    height: 12vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    font-weight: 600;
}

a:hover {
    color: #fff;
}
.no-padding-modal .modal-dialog .modal-content{
    padding: 0px !important;
}
.navbar {
    position: fixed;
    display: flex;
    height: fit-content;
    /* justify-content: space-between; */
    align-items: center;
    /* padding: 0.7rem 2rem; */
    /* overflow: hidden; */
    z-index: 1;
    width: 100%;
    /* margin: auto; */
    /* top: 0; */
    /* border-bottom: solid 1px var(--primary-color); */
    /* opacity: 0.9; */

    /* position: fixed; */
    /* top: 0; */

    /* box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.25); */
    /* box-shadow: 12px 0 15px -4px rgba(31, 73, 125, 0.8),
      -12px 0 8px -4px rgba(31, 73, 125, 0.8);
    box-shadow: 0 9px 0px 0px white, 0 -9px 0px 0px white,
      12px 0 15px -4px rgba(31, 73, 125, 0.8),
      -12px 0 15px -4px rgba(31, 73, 125, 0.8); */
}

.custom-change {
    background-color: #000;
    border: 1px solid rgb(153 153 153 / 64%);
}

.dropdown-item:hover,
.dropdown-item:focus {
    color: #ffe607 !important;
    background-color: #000000 !important;
}

.active .dropdown-toggle {
    color: #000;
}

.f-white {
    color: #fff;
    font-size: 14px;
}

a.f-white :hover {
    color: #ffe607 !important;
    font-size: 14px;
}

.dropdown-item.active,
.dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: transparent;
}

@keyframes fadein {
    from {
        opacity: 0.9;
    }

    to {
        opacity: 1;
    }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.nav-logo {
    color: #fff;
    margin-left: 50px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(7, auto);
    grid-gap: 30px;
    text-align: center;
    width: 79vw;
    margin-top: 1%;
    list-style: none;
    justify-content: end;

}

.navbar.active {
    background-color: #fff;
    color: #000 !important;
    animation: fadein ease-in-out 0.2s;
    transition: background 1s, height 2s;
    z-index: 99;
}

#text-dark {
    color: #000 !important;
}

.nav-links.active {
    color: #000;
}

.blognavlink {
    color: rgb(0, 0, 0) !important;
}

.blognavlink {
    color: rgb(255, 255, 255) !important;
    text-decoration: none;
    padding: 0.5rem 1rem;
}

.blog-black {
    color: rgb(0, 0, 0);
    text-decoration: none;
    padding: 0.5rem 1rem;
}

.nav-links1.active {
    color: rgb(255, 255, 255);
}

.nav-links {
    color: #fff;
    text-decoration: none;
    padding: 0.5rem 1rem;
}

.main-nav-active {
    border-bottom: 2px solid #3550CE;
}

.main-nav-active_one {
    border-bottom: none !important;
}



.menu-icon {
    display: none;
}



@media only screen and (max-width: 960px) {
    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: calc(100vh - 56px);
        position: absolute;
        justify-content: start;
        margin-top: 0;
        top: 100%;
        left: -100%;
        transition: all .4s ease;
        overflow: auto;
        opacity: 0;

    }

    .menu-icon {
        display: inline-flex;
        position: absolute;
        top: 0;
        right: 0;
        font-size: 1.8rem;
        color: #fff;
        transform: translate(-18%, 18%);
        cursor: pointer;
        gap: 16px;
    }

    .color-black {
        color: #000 !important;
    }

    .main-nav-active {
        border-bottom: none;
    }

    .nav-menu.active {
        background-color: #fff;
        left: 0;
        opacity: 1;
        transition: all .5s ease;
        z-index: 1;
        color: black;
    }

    /* .nav-links:hover{
        border-bottom: none;
        background-color: black;
        color: #fff;
        transition: .5s ease-in-out;
    } */
    .navbar {
        /* position: relative; */
    }

    .nav-links {
        text-align: center;
        padding: 1rem;
        width: 100%;
        display: table;
        color: black;
    }

    .blognavlink {
        color: rgb(0, 0, 0) !important;
        text-decoration: none;
        padding: 0.5rem 1rem;
    }

    .nav-logo {
        margin-left: 10px;
    }

    /* .nav-logo{ */
    /* position: absolute; */
    /* top: 0;
        left: 0; */
    /* transform: translate(25%,50%); */
    /* margin-left: 0; */
    /* } */
}

@media only screen and(max-width:400px) {
    .nav-logo {
        font-size: .7rem;
    }

    .menu-icon {
        font-size: 1rem;
        transform: translate(1%, 25%);
    }




}

@media only screen and(max-width:700px) {
    .nav-logo img {
        width: 120px;
    }
}

@media screen and (max-device-width: 600px) {

    .menu-icon {
        display: flex;
        position: absolute;
        top: 0;
        /* right: -75px; */
        font-size: 1.8rem;
        color: #fff;
        /* transform: translate(-100%,-25%); */
        cursor: pointer;
    }

    .phone {
        color: #000;
        font-size: 21px;
    }

    .ml-4-font {
        margin-left: 30px;
        font-size: 20px;
    }

    .navbar {
        background-color: black;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1.2rem;
        position: fixed;
    }

    .nav-menu.active {
        background-color: #fff;
        left: 0;
        opacity: 1;
        transition: all .5s ease;
        z-index: 1;
        color: black;

    }

    .nav-logo img {
        width: 120px;
    }

}

.menu-icon a{
    color: #fff;
    font-size: 28px;
}


@media screen and (max-width:960px) {
    .dropdown-menu.custom-change{
        position: static;
    border-radius: 0;
    border: 0;
    }
}

@media only screen and(max-width:600px) {
    .menu-icon .phone.ml-4-font{
        margin: 0 !important;
    }
}