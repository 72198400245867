@media only screen and (max-width: 700px) {
  .slick-next {
    display: block !important;
  }

  .slick-prev {
    display: block !important;
  }
}

.border-right-color {
  border-right: .25rem solid #000;
}

.title-testi h3 {
  font-weight: 800;
  font-size: 1.8125rem;
}

.title-testi p {
  font-size: 18px;
}

.image-profile img {
  border-radius: 50%;
}

.profile-name h4 {
  font-weight: 600;
  font-size: 1.25rem;
}

.profile-name {
  margin-left: 20px;
}

.profile-name p {
  font-size: 1rem;
}

.cursor-point {
  cursor: pointer;
}