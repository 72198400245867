.line-height-1-0-5 {
    line-height: 1.05;
}

.text-white {
    color: #fff !important;
}

.word-slider {
    vertical-align: middle;
    height: 2rem;
}

.d-inline-block {
    display: inline-block !important;
}

.position-relative {
    position: relative;
}

.list__circle {
    opacity: 0;
    -webkit-transition: opacity .2s ease-in-out;
    transition: opacity .2s ease-in-out;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}

.list__circle::before {
    content: "";
    position: absolute;
    right: 0;
    left: 0;
    transform: translate(60%, 18%);
    -webkit-transform: translate(60%, 18%);
    -o-transform:translate(60%, 18%);
    -moz-transform:translate(60%, 18%);
    -ms-transform:translate(60%, 18%);
    /* margin: 0 auto; */
    /* top: 16px; */
    width: 12.5rem;
    height: 12.5rem;
    border-radius: 50%;
    background-color: #fceb5d;
}
.full-section a.highlight-circle{
    display: block;
}

.highlight-circle:hover .list__circle {
    opacity: 1;
}

.z-index-2 {
    z-index: 2;
}

.z-index-1 {
    z-index: 1;
}

.ms-header {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: sans-serif;
    width: 100vw;
    height: 100vh;
    background: linear-gradient(to right bottom, #9dd7d5, #fea096);
}

.ms-header__title {
    flex: 1 1 100%;
    width: 100%;
    text-align: center;
    font-size: 4rem;
    font-weight: bold;
    color: #fff;
    text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4);
}


.p-0 {
    padding: 0 !important;
}

.m-0 {
    margin: 0 !important;
}

.line-height-1 {
    line-height: 0.8;

}

.mt-100 {
    margin-top: 100px;
}

.mt-150 {
    margin-top: 150px;
}


.mt-200 {
    margin-top: 200px;
}

.banner-desc {
    color: #bebebe;
    font-size: 1.4rem;
}

.top-bot-border {
    border-top: 4px solid #fff;
    border-bottom: 4px solid #fff;
    margin-bottom: 20px;
}

.style_1 {
    background-color: #0f35f7;
    color: #ffe607;
}

.style_2 {
    background-color: #ee67d8;
    color: #ffe607;
}

.style_3 {
    background-color: #e9301c;
    color: #ffe607;
}

.style_4 {
    background-color: #707a00;
    color: #ffe607;
}

.bordertop {
    border-top: 3px solid #fff;
}

.borderbottom {
    border-bottom: 3px solid #fff;
}

.pt-4r {
    padding-top: 4rem;
}

.pb-6r {
    padding-bottom: 4rem;
}

.f-57 {
    font-size: 57px;
}

.h4{
    font-size: 2.25rem;
    font-weight: 700 !important;
}

.border-card {
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.yellow-bg {
    background-color: #ffe607;
    padding-bottom: 60px;
}

.sec-6-head h1 {
    color: #020202;
    font-size: 45px;
}

.button-custom {
    padding: 1.5rem 2.5rem;
    font-weight: 600;
    color: #000 !important;
    border: 2px solid #000;
    background-color: transparent;
    font-size: 16px;
}

.button-custom:hover {
    color: #fff !important;
    background-color: #000;
}


.font-custom h1,  h3.homepage-hero-section__title{
    font-size: 3.8rem;
    font-weight: 800 !important;
}

.uvp-list ul {
    list-style: none;
}

.uvp-list ul>li {
    clear: left;
    padding: .5rem 0;
    color: #ccc;
}

.uvp-list ul li a {
    color: #ccc;
}

.uvp-list ul li a:before {
    content: "";
    height: 1.5rem;
    width: 1.5rem;
    display: block;
    float: left;
    margin-left: -1.5rem;
    background-position: center;
    background-repeat: no-repeat;
}

.uvp-list li a:before {
    background: url("../asset/img/arrow.svg");
    background-size: cover;
    background-position: center;
    padding: .15rem;
}

.addtional .accordion-button,
.addtional .accordion-item {
    border: 1px solid #666 !important;
}

.addtional .accordion-button:not(.collapsed) {
    color: #000;
}

@media only screen and (max-width: 600px) {
    .h4 {
        font-weight: 700;
        font-size: 1.4375rem !important;

    }

    .heading-full-growth h1 {
        color: #202020;
        font-family: "Lato", Sans-serif !important;
        font-size: 24px;
        font-weight: 600;
        margin-top: 6%;
    }

    .button-custom {
        padding: 0.5rem 2.5rem;
        font-weight: 600;
        color: #000 !important;
        border: 2px solid #000;
        background-color: transparent;
        font-size: 16px;
    }
}