@media only screen and (max-width: 700px) {
    .slick-next{
        display: none;
      }
      .slick-prev {
        display: none;
      }
      .section3-carousel{
          margin: 15px;
      }
}
