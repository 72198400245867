.services,
.contactus,
.digitalmarketinginternship {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  height: 90vh;
}

.homepage-container1 {
  /* padding: 2rem; */
  background-color: black;
  color: #fff;

}

.video-homepage video {
  width: 100%;
  margin-top: 0px;

}

.homepage-text1 h1 {
  text-align: left;
  font-family: "Lato", Sans-serif;
  font-size: 50px;
  color: #fff;
  font-weight: 600;
  line-height: 70px;
}

.homepage-text2 {
  border-right: 2px solid white;
  padding: 2.5rem 1rem;

}

.homepage-text2 h4 {
  color: #FFF;
  font-family: "Lato", Sans-serif;
  font-size: 21px;
  font-weight: 700;
  line-height: 1.4em;
}

.homepage-text2 p {
  margin-top: 15%;
  color: #b0b0b0;
  font-family: "Lato", Sans-serif;
  font-size: 18px;
  line-height: 33.4286px
}

/* .icon-1 img{
  margin-top: 60px;
} */
.img-icon {
  width: 90%;
}

.homepage-icons {
  padding-left: 50px;
}

.gallery-caption {
  padding: 5%;
  border-radius: 0 0 2px 2px;
  border: 1px solid #eaeaea;
  border-top: none;
  margin-left: 5%;
  font-size: .9em;
  color: #fff !important;
  ;
}

.padding-icons img {
  padding-left: 20%;
}

.section2 {
  background-color: #FCEB5D;
}

.mark-words {
  background-color: black;
  color: #FCEB5D;
}

.sec-2-heading h1 {
  font-size: 34px;
  font-weight: 700;
  color: #060606;
}

.sec-2-paragraph p {
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;

  font-family: Lato, sans-serif;

}

.section3-carousel {
  background-color: #fff;

}

.sec-2-carousel h2 {
  font-size: 31px;
  font-weight: 700;
  color: #000;
  font-family: "Lato", sans-serif;

}

.slick-prev:before,
.slick-next:before {
  color: black !important;
  width: 40px;
  height: 30px;
}

.testimonial-img {
  border-radius: 100px;
  align-items: center;
}

.testimonial-slider p {
  color: #FFF !important;
  font-family: "Roboto", Sans-serif;
  font-size: 15px;
  font-style: italic;
  font-weight: 400;
}

.testimonial-slider h5 {
  color: #FFF !important;

}

.testimonial-slider h6 {
  color: #FFF !important;

}

.heading-full-growth h1 {
  color: #202020;
  font-family: "Lato", Sans-serif !important;
  font-size: 34px;
  font-weight: 600;
  margin-top: 6%;
}

.full-growth-bg {
  background-color: transparent;
  background-image: linear-gradient(180deg, #F8F8F8 0%, #FBFBFB 100%);
}

.text-none-underline {
  text-decoration: none !important;
}

.full-growthcontent {
  box-shadow: 0 0 10px 0 rgb(232 232 232 / 50%);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 5%;
  padding: 30px 20px 20px 30px;
  height: 95%;
  background-color: #fff;
}

.full-growthcontent h6 {
  color: #060606;
  font-family: "Lato", Sans-serif;
  text-decoration: none;
  font-size: 16px;
  font-weight: 700;

}

.full-growthcontent p {
  font-size: 10.5pt;
  margin-top: 10%;
  font-family: Arial;
  color: rgb(0, 0, 0);
  background-color: transparent;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  vertical-align: baseline;

}

.growth-button {
  font-family: "Roboto", Sans-serif;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  fill: #FFF;
  color: #FFF;
  background-color: #000;
  border-style: solid;
  border-width: 0 2px 2px 0;
  border-color: #FFF;
  border-radius: 5px 5px 5px 5px;
  box-shadow: 1px 1px 5px 1px rgb(88 103 220 / 84%);
  padding: 15px 30px 15px 30px;
  transition: all .4s ease-in-out;
}

.growth-button:hover {
  background-color: #fff;
  color: #000;
  transform: scale(0.90);
}

.homepage-container4 {
  padding: 2rem;
  background-color: black;
  color: #fff;
}

.border-result {
  box-shadow: 0 0 15px 0 rgb(82 82 82 / 50%);
  padding: 10px;
}

.img-result {
  width: 100%;
  padding: 10px;
}

.bgcolor-result {
  background-color: #F0F0F0;

}

.bg-result {
  background-color: transparent;
  background-image: linear-gradient(180deg, #F6F6F6 0%, rgba(202, 202, 202, .54) 100%);
  width: 100%;
  min-height: 50px;
  color: #f00;
  text-align: center;
  /* background:#000 url() no-repeat 0 0; */
  background-size: 60%;
  /* display:flex;
  align-items:center;
  justify-content:center; */

  border-top-left-radius: 35%;
  border-top-right-radius: 35%;
}

.border-card {
  border: none !important;
}

.blog-text {
  font-size: 14px;

  color: #777777;
}

.blog-sub {
  color: #adadad;
  font-size: 12px !important;
}

.blog-title {
  font-size: 18px !important;
}

.blog-link {
  font-size: 12px;
  text-decoration: none;
  color: #000;
  font-weight: 600;
}

.blog-img {

  height: 200px
}

.ready-kick-bg {
  background-color: #000;
  padding-bottom: 90px;
  margin-top: -7px;
  z-index: 999999999;
}

.custom-shape-divider-top-1635236144 {
  position: relative;
  top: 0;
  left: 0;
  z-index: -999;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  /* transform: rotate(180deg); */
}

.mt-10 {
  margin-top: 10%;
}

.custom-shape-divider-top-1635236144 svg {
  position: relative;
  display: block;
  width: calc(156% + 1.3px);
  height: 132px;
}

.custom-shape-divider-top-1635236144 .shape-fill {
  fill: #000;
}

.sec-6-head h1 {
  color: #d8d8d8;
  font-size: 34px;
  font-weight: 600;
  font-family: "Lato", sans-serif;
}

.ready-kick-btn {
  font-family: "Roboto", Sans-serif;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  fill: rgba(0, 0, 0, .97);
  color: rgba(0, 0, 0, .97);
  background-color: #FFF;
  border-style: solid;
  border-width: 0 2px 2px 0;
  border-color: #FFF;
  border-radius: 5px 5px 5px 5px;
  box-shadow: 1px 1px 5px 1px rgb(88 103 220 / 84%);
  padding: 15px 30px 15px 30px;
  transition: all .4s ease-in-out;
}

.ready-kick-btn:hover {
  background-color: #000;
  color: #fff;
  transform: scale(0.90);
}

.img-result-page {
  width: 99%;
  /* border: 1px solid #333; */
}

.result-page-heading h1 {
  font-size: 30px;
  font-family: Helvetica;
  font-weight: 400;

}

.result-page-heading p {
  font-size: 15px;
  margin-top: 20px;
  color: #3a3a3a;
}

.contactus-bg {
  background-color: #000;
  padding: 40px 0 50px 0;
  display: flex;
  align-items: center;
}

.contactus-heading h1 {
  font-size: 37px;
  margin-top: 20px;
  font-weight: 600;
  line-height: 50px;
  color: #575757;
  font-family: 'roboto', sans-serif;
}

.contactus-heading p {
  font-size: 16px;
  color: #fff;
  margin-top: 40px;
  padding: 0 40px 0 0;
}

.contactus-heading h6 {
  color: #e5e5e5;
  margin-top: 20px;
}

.contactus-heading h6 a {
  color: #e4e4e4;
}

.space-100 {
  height: 200px;
}

.contact-form-bg {

  background-color: white;
}

.contact-form-bg h1 {

  font-size: 37px;

  font-weight: 600;
  line-height: 50px;
  color: #000;
  font-family: 'roboto', sans-serif;
  padding: 100px 20px 10px 20px;
}

.contact-from-digi {
  padding: 20px;
}

.contactform {
  margin: 20px;
}

input {

  height: 30px;
  width: 100%;
  border-left: 2px solid #585858;
  border-bottom: 1px solid #585858;
  border-right: none;
  border-top: none;
  padding: 20px;
}

textarea {
  border-left: 2px solid #585858;
  border-bottom: 1px solid #585858;
  padding: 20px;
  border-right: none;
  border-top: none;
  width: 100%;
}


.contact-submit-btn {
  background-color: #2B2B2B;
  color: white;
  width: 100%;
  padding: 10px;
  border: none;
  transition: all .4s ease-in-out;
}

.contact-submit-btn:hover {
  background-color: #000;
  color: #fff;
  transform: scale(0.90);
}

.social-banner {
  background: url('./asset/img/socialbanner.png');
  background-size: auto;
  height: auto;
}

.social-heading h2 {
  color: #fff;
  margin-top: 60px;

  font-size: 28px;
}

.social-heading h6 {
  margin-top: 7%;
  font-weight: bold;
  color: #fff;
  line-height: 28px;
  padding-bottom: 30px;
}

.social-media {
  width: 85%;
}

.social-section h1 {
  color: #070707;
  font-size: 23px;
  font-weight: 700;
  font-family: "Lato", sans-serif;
}

.social-section p {
  margin-top: 10%;
  color: #272727;
  font-size: 15px;

}

.about-sec-bg {
  background-color: #000;
}

.about-sec1-heading h1 {
  color: white;
  font-size: 48px;
  font-weight: 600;
  font-family: Lato, sans-serif;
}

.about-sec1-heading p {
  font-size: 17px;
  color: #fff;
  line-height: 25.5px;
  font-weight: 500;
}

.about-sec2-heading h2 {
  color: white;
  font-size: 34px;
  font-weight: 600;
}

.about-sec-img {
  position: relative;
  width: 100%;

  background-color: #000;
}

.about-img-sec2 {
  display: flex;
  justify-content: center;
}

.about-heading-sec2 h1 {
  font-size: 31px;
  font-weight: 600;
  color: #000;
  line-height: 41.2px;


}

.about-sec2-btn {
  padding: 10px 20px 10px 20px;
  background-color: #000;
  color: #fff;
  border: none;
  transition: all .4s ease-in-out;
}

.about-sec2-btn:hover {
  background-color: #2B2B2B;
  ;
  color: #fff;
  transform: scale(0.90);
}

.client-logo {
  background-color: #F6F6F6;
}

.client-logo-heading h1 {
  font-size: 35px;
  font-weight: bold;
  color: #000;
}

.client-logo-img {
  display: flex;
  justify-content: center;
}

.about-kick-off {
  margin-top: -70px;
}

.space-1 {
  height: 80px;
}

.space-2 {
  height: 80px;
}

.maven-box1-edit {
  border-radius: 23px 23px 23px 23px;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color: #000;
  box-shadow: 0 0 5px 0 rgb(0 0 0 / 50%);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 10px 10px 10px 10px;
  padding: 40px 40px 40px 40px;
  /* height: 250px; */
}

.maven-box1-edit h1 {
  font-size: 30px;
  color: #3a3a3a;
  font-weight: 700;
}

.maven-box1-edit p {
  margin-top: 30px;
  font-size: 20px;
}

.maven-box2-edit {
  background-color: #000;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 10px 10px 10px 10px;
  padding: 30px 30px 30px 30px;
  border-radius: 23px 23px 23px 23px;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
}

.maven-box2-edit h1 {
  font-size: 30px;
  color: #fff;
  font-weight: 700;
}

.maven-box2-edit p {
  color: #fff;
  margin-top: 20px;
  font-size: 20px;
}

.sec2-maven-bg {
  background-color: black;

}

.challenges-sec {
  padding: 40px 40px 40px 40px;
}

.challenges-sec h1 {
  font-size: 30px;
  color: #fff;
  font-weight: 700;
}

.challenges-sec p {
  color: #fff;
  margin-top: 20px;
  font-size: 18px;
}

.sec3-maven-bg {
  background-color: white;
}

.Approach-sec {
  padding: 40px 40px 40px 40px;
}

.Approach-sec h1 {
  font-size: 30px;
  color: #3a3a3a;
  font-weight: 700;
}

.Approach-sec p {
  color: #000;
  margin-top: 20px;
  font-size: 18px;
}

.deco-box2-edit {
  background-color: #000;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 10px 10px 10px 10px;
  padding: 30px 30px 30px 30px;
  border-radius: 23px 23px 23px 23px;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
}

.deco-box2-edit h1 {
  font-size: 30px;
  color: #fff;
  font-weight: 700;
}

.deco-box2-edit p {
  color: #fff;
  margin-top: 30px;
  font-size: 20px;
}

.deco-box1-edit {
  border-radius: 23px 23px 23px 23px;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color: #000;
  box-shadow: 0 0 5px 0 rgb(0 0 0 / 50%);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 10px 10px 10px 10px;
  padding: 40px 40px 40px 40px;
}

.deco-box1-edit h1 {
  font-size: 30px;
  color: #3a3a3a;
  font-weight: 700;
}

.deco-box1-edit p {
  margin-top: 10px;
  font-size: 20px;
}

.deco-Approach-sec h1 {
  background-color: black;
  text-align: center;
  font-size: 30px;
  color: #fff;
  font-weight: 700;
  margin-top: 30px;
  padding: 8px 0 8px 0;

}

.deco-Approach-sec p {
  color: rgb(0, 0, 0);
  margin-top: 30px;
  font-size: 20px;
}

.deco-result-img img {
  border-radius: 10px;
  border-style: solid;
  border-width: 3px;
}

.thankyou-banner {
  background: url('./asset/img/thankyou.png');
  background-size: cover;
  width: 100%;
  padding: 0;
  margin: 0;
  background-repeat: no-repeat;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.thankyou-content h1 {
  color: #3a3a3a;
  font-size: 43px;
  font-weight: 700;


}

.icon-thankyou {
  font-size: 50px;
  color: #000;
  margin-top: 20px;
}

.animated {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both
}

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-500px);
    transform: translateX(-500px)
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0)
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-500px);
    -ms-transform: translateX(-500px);
    transform: translateX(-500px)
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0)
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft
}

.mob-res-tracking {
  display: none;
}

.desktop-tracking {
  display: block;
}

.digital-page-sec1-heading h1 {
  font-weight: 600;
  font-size: 45px;
  color: #3a3a3a;
  /* font-family: 'Lato,Sans-serif'; */
}

.digital-icon-edit {
  color: #0F3DC1;
  font-size: 60px;
  padding-top: 10px;
}

.figure-digi {
  color: #000 !important;
  font-size: 14px !important;
}

.icon-digi Figure.Caption {
  color: #000;
}

.digi-sec1-p p {
  color: #333;
  padding: 10px;
  font-size: 17px;
  line-height: 27px;
  font-family: 'Montserrat', sans-serif;
}

.digi-sec2-para p {
  color: #585858;
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;

}

.digi-sec2-para h1 {
  font-weight: 600;
  font-size: 45px;
  color: #3a3a3a;

}

.gradiant {
  background-color: transparent;
  background-image: linear-gradient(140deg, #ebb0e7 0%, #2642D3 100%);
  padding: 6%;
  font-family: 'Montserrat', sans-serif;
}

.sec3-digi h1 {
  font-size: 47px;
  font-weight: bold;
  color: white;
  text-align: center;
  margin: 0%;
  font-family: 'Montserrat', sans-serif;

}

.sec3-digi p {
  font-size: 50px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  color: white;
  text-align: center;
  margin: 0%;
  font-family: 'Montserrat', sans-serif;
}

.register-btn-digi {
  text-align: center;
  color: #0F3DC1;
  border: none;
  background-color: white;
  padding: 7px 40px 7px 50px;
  border-radius: 30px;
  font-family: 'Montserrat', sans-serif;
  transition: .2s ease-in-out;
}

.register-btn-digi2 {
  text-align: center;
  background-color: #0F3DC1;
  border: none;
  color: white;
  padding: 7px 40px 7px 50px;
  border-radius: 30px;
  font-family: 'Montserrat', sans-serif;
  transition: .2s ease-in-out;
}

.sec4-digi {
  background-color: #F4F4F4;
}

.register-btn-digi2:hover {
  background-color: #FFFFFF00;
  color: #000;
  border: 1px solid #000;
}

.register-btn-digi:hover {
  background-color: #FFFFFF00;
  color: #fff;
  border: 1px solid #fff;
}

.digi-sec4-bx h1 {
  font-family: 'Raleway', sans-serif;
  font-size: 21px;
  font-weight: 600;
}

.digi-sec4-bx p {
  font-family: 'Helvetica', sans-serif;
  font-size: 17px;
  line-height: 30px;
  padding: 20px 40px 40px 40px;
  color: #272727;

}

.testi-digi h1 {
  font-family: "Lato", Sans-serif;
  font-size: 40px;
  font-weight: 700;

}

.testi-digi-slide {
  padding-top: 20px;
}

.testi-digi-slide p {
  font-weight: 500;
  font-size: 18px;
}

.hero {
  position: relative;
  background-color: #E6E9EC;


}

.hero:after,
.hero:after {
  z-index: -1;
  position: absolute;
  top: 98.1%;
  left: 50%;
  /* margin-left: -25%; */
  content: '';
  width: 0;
  height: 0;
  border-top: solid 13px #E6E9EC;
  border-left: solid 12px transparent;
  border-right: solid 12px transparent;
}

.testi-content {
  padding-top: 10px;
}

.testi-content p {
  font-size: 13px;
}


.digi-contact-form h1 {
  font-size: 27px;
  color: #fff;
  font-weight: 600;
  text-align: center;
}

.digi-contact-form {
  background-color: #000;
}

.digi-contact-form p {
  font-size: 21px;
  font-weight: 500;
  text-align: center;
  color: #fff;
  margin-top: 50px;
}

.business-banner {
  /* background-image: url('') rgba(0, 0, 0, 0.61); */
  background-image: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)), url(./asset/img/business.png);


}

.business-cont h1 {
  color: white;
  font-size: 38px;
  line-height: 50px;
  font-family: 'Roboto Condensed', sans-serif;
}

.business-cont p {
  color: rgb(194, 194, 194);
  padding: 10px;
  line-height: 30px;
  /* letter-spacing: 1px; */
}

.business-frm h1 {
  color: white;
}

.business-field {
  border-radius: 5px;
  margin-top: 20px;
}

.bussiness-btn {
  width: 100%;
  margin-top: 30px;
  padding: 7px;
  color: #fff;
  border: none;
  background-color: rgb(168, 174, 184)
}

.btn-business-1 {
  padding: 10px 50px 10px 50px;
  font-family: Helvetica;
  border-radius: 10px;
  border: none;
  font-size: 20px;
  letter-spacing: 4px;
  background-color: rgb(238, 241, 55);
}

.business-field1 {
  border-radius: 5px;
  border: 1px solid #b6b6b6;
  margin-top: 20px;
}

.std-banner {
  background: url('./asset/img/std-banner.png');
  /* height: 90vh; */
  height: 85vh;
  background-repeat: no-repeat;
  background-size: cover;

}

.std-bg-img {
  background: url('./asset/img/socialbanner.png') rgba(0, 0, 0, 0.61);
  background-blend-mode: overlay;
}

.std-box {
  color: white;

}

.std-box h1 {
  font-size: 23px;
  font-weight: bold;
  color: #fff;
  padding: 10px 30px 10px 30px;
}

.std-box p {
  line-height: 26px;
  font-size: 17px;
  padding: 10px 40px 10px 40px;
}

.std-icon {
  font-size: 50px;
}

.icon-digi .std-caption {
  font-size: 17px;
  margin-top: 30px;
}

.famphy-banner {
  background: url('./asset/img/Fambanner.png');
  /* height: 90vh; */
  height: 96vh;

  background-repeat: no-repeat;
  background-size: cover;
}

.mt-6 {
  margin-top: 70px;
}

.height-famphy {
  height: 63px;
}

.mr-6 {
  padding: 20px;
}

.mt-mob-10 {
  margin-top: 10%;
}

.video-mob {
  width: 100%;
}

/* mobile screen */

@media only screen and (max-width: 700px) {
  .digi-contact-form p {
    font-size: 15px !important;
    padding: 0px 20px;
  }

  .digi-contact-form h1 {
    font-size: 16px !important;
  }

  .mt-6 {
    margin-top: 0px;
  }

  .video-mob {
    margin-top: 100px;

  }

  .icon1 Figure.Caption {
    border: none !important;
  }

  .homepage-icons {
    padding-left: 0%;
  }

  .img-icon {
    width: 100%;
  }

  .video-homepage video {
    width: 100%;
    margin-top: 70px;

  }

  .gallery-caption {
    padding: 5%;
    border-radius: 0 0 2px 2px;
    border: none;
    border-top: none;
    margin-left: 5%;
    font: size 14px !important;
    color: #fff !important;
  }

  .homepage-text2 {
    border-right: none;
    padding: 2.5rem 1rem;

  }

  .homepage-text1 h1 {
    text-align: left;
    font-family: "Lato", Sans-serif;
    font-size: 40px;
    font-weight: 600;
    line-height: 50px;

  }

  .homepage-mob {
    margin-top: 50px;
  }

  .sec-2-heading h1 {
    font-size: 24px;
    font-weight: 700;
    color: #060606;
  }

  .social-heading h2 {
    margin-top: 0px !important;
  }

  .homepage-container1 {
    padding: 0.5rem;
    background-color: black;
    color: #fff;

  }

  .figure-caption {
    font-size: 12px !important;
  }

  .padding-icons img {
    padding-left: 0%;
  }

  .center {
    text-align: center;
    display: block;
    justify-content: center;
    align-items: center;
    margin: auto;
    /* width: 100%; */
  }

  .testimonial-slider p {
    text-align: center;
    color: #fff !important;
    margin-top: 10px;
  }

  .testimonial-slider h5 {
    text-align: center;
    color: #fff !important;
  }

  .testimonial-slider h6 {
    text-align: center;
    color: #fff !important;
  }

  h5 {
    color: white !important;
  }

  .blog-img {


    height: 130px;

    width: 100%;
  }

  .space-2 {
    height: 20px;
  }

  .about-sec1-heading h1 {
    color: white;
    font-size: 28px;
    margin-top: 60px;
  }

  .slider-mob-mt {
    margin-top: 60px;
  }

  .heading-full-growth h1 {
    margin-top: 60px;
  }

  .space-100 {
    height: 80px;
  }

  .mob-res-tracking {
    display: block;
  }

  .desktop-tracking {
    display: none;
  }

  .client-logo-mob {
    display: block !important;
  }

  .client-logo-des {
    display: none;
  }

  .digital-page-sec1-heading h1 {
    font-size: 23px;
    text-align: center;
    margin-top: 20px;
  }

  .std-banner {
    height: 23vh;
  }

  .famphy-banner {
    height: 36vh;
  }

  .testi-digi h1 {
    font-size: 26px;
  }

  .sec3-digi h1 {
    font-size: 27px;
  }

  .sec3-digi p {
    font-size: 23px;
  }

  .contact-form-bg h1 {
    font-size: 26px;
    padding: 30px 20px 10px 20px;
  }

  .digi-sec2-para h1 {
    font-size: 26px;
  }

  .mt-mob-10 {
    margin-top: 30px !important;
  }

  .mt-mob-11 {
    margin-top: -20px !important;
  }

  .text-center.testi-digi-slide.hero.mb-5.col-md-10 {
    /* margin: 5px; */
    margin-left: 13px;
    width: 90%;
  }

}

.client-logo-mob {
  display: none;
}



/* For styling the demo. Not required. */

.title {
  color: #FCEB5D;
  text-transform: lowercase;
  font-weight: bold;
  font-size: 50px;
  position: absolute;
  line-height: 60px;
  letter-spacing: 1px;
}

.pre-title {
  color: #FCEB5D;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 30px;
  line-height: 50px;
  letter-spacing: 1px;
}

.word {
  margin: auto;
  color: white;
  font: 700 normal 2.5em 'tahoma';

}

.typing {
  height: auto;
  margin-left: 10px;
  position: relative;
}

.typing::after {
  content: "";
  position: absolute;
  right: -5px;
  width: 1px;
  height: 100%;
  /*   border-right: 2px solid white; */
  animation: blink 0.5s ease infinite;
}

@keyframes blink {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 600px) {
  .mt-3 {
    margin-top: 0px !important;
  }

}

@media only screen and (max-width: 500px) {
  .blog-img {


    height: auto;

    width: 100%;
  }
}

.elementor-1702 .elementor-element.elementor-element-14e75fc5 {
  --iteration-count: infinite;
  --animation-duration: 1200ms;
  --dynamic-text-color: #FFF;
}

.elementor-widget:not(:last-child) {
  margin-bottom: 20px;
}

.elementor-widget:not(:last-child) {
  margin-bottom: 20px;
}

/* .elementor-widget {
    position: relative;
} */
.elementor-element .elementor-widget-container {
  text-align: center;
  -webkit-transition: background .3s, border .3s, -webkit-border-radius .3s, -webkit-box-shadow .3s;
  transition: background .3s, border .3s, -webkit-border-radius .3s, -webkit-box-shadow .3s;
  -o-transition: background .3s, border .3s, border-radius .3s, box-shadow .3s;
  transition: background .3s, border .3s, border-radius .3s, box-shadow .3s;
  transition: background .3s, border .3s, border-radius .3s, box-shadow .3s, -webkit-border-radius .3s, -webkit-box-shadow .3s;
}

.elementor-1702 .elementor-element.elementor-element-14e75fc5 .elementor-headline {
  text-align: center;
  font-family: "Roboto", Sans-serif;
  font-size: 32px;
  font-weight: 500;

}

.elementor-headline--style-highlight .elementor-headline-dynamic-wrapper {
  overflow: visible;
  text-align: inherit;
}

.elementor-headline-dynamic-wrapper {
  display: inline-block;
  position: relative;
}

.elementor-headline-dynamic-wrapper .elementor-headline-dynamic-text.elementor-headline-text-active {
  position: relative;
}

.elementor-headline-dynamic-wrapper .elementor-headline-dynamic-text {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}

.elementor-headline--style-highlight .elementor-headline-dynamic-text {
  z-index: 1;
}

.elementor-headline-dynamic-text {
  color: var(--dynamic-text-color, #ffffff);
}

.elementor-headline--style-highlight svg {
  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  overflow: visible;
}

path {
  stroke-width: 8px !important;
}

.elementor-headline.e-animated svg path {
  -webkit-animation: elementor-headline-dash forwards;
  animation: elementor-headline-dash forwards;
  -webkit-animation-duration: var(--animation-duration, 1.2s);
  animation-duration: var(--animation-duration, 1.2s);
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

.elementor-headline--style-highlight svg path {
  stroke: red;
  stroke-width: 9;
  fill: none;
  opacity: 1;
  stroke-dasharray: 1500 1500;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
}

.elementor-headline.e-hide-highlight svg path {
  opacity: 1;
  stroke-dasharray: 0 1500;
  -webkit-animation: hide-highlight .4s forwards;
  animation: hide-highlight .4s forwards;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

.alt-2 {
  animation: typing 2s steps(25) infinite alternate, Undo-typing 7s 7s steps(24),
    blink 0.5s infinite alternate;
  white-space: nowrap;
  word-wrap: break-word;
  overflow: hidden;
  width: 37ch;
  /* border-right: 5px solid; */
}

@keyframes typing {
  from {
    width: 0;
  }

  to {
    width: 37ch;
  }
}

@keyframes Undo-typing {
  from {
    width: 37ch;
  }

  to {
    width: 0;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}

#changingText {
  color: #ffffff;
  border-right: 1px solid black;
  animation: cursoranim 1s linear infinite;
}

@keyframes cursoranim {
  50% {
    border-right: 1px solid rgba(0, 0, 0, 0);
  }
}

.preview-btn[disabled] {
  color: #999
}

.next-btn[disabled] {
  color: #999
}

.contact-submit-btn[disabled] {
  background-color: #818181;
  opacity: 0.9;
}

.output {
  text-align: center;
  font-family: 'Source Code Pro', monospace;
  color: white;

  font-size: 30px;
}

/* Cursor Styling */

.cursor::after {
  content: '';
  display: inline-block;
  margin-left: 3px;
  background-color: white;
  animation-name: blink;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
}

/* h1.cursor::after {
  height:24px;
  width:13px;
}
p.cursor::after {
  height:13px;
  width:6px;
} */

@keyframes blink {
  0% {
    opacity: 1;
  }

  49% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.fa-seacrh-icon {
  position: absolute;
  margin-top: 12px;
  margin-left: -31px;
}

p.mt-4.card-text {
  height: 108px;
  overflow: hidden;
}

.accordion-container .accordion-title {
  position: relative;
  margin: 0;
  margin-top: 20px;
  padding: 1em 0.625em 1em 2em;
  background-color: rgb(216, 216, 216);
  font-size: 19px;
  font-weight: bold;
  color: rgb(0, 0, 0);
  cursor: pointer;
}

.accordion-container .accordion-title:hover,
.accordion-container .accordion-title:active,
.accordion-title.open {
  background-color: #000000;
  color: #fff;
  transition: all 1s ease-in-out;

}

.accordion-container .accordion-title::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 25px;
  width: 15px;
  height: 2px;
  transform: rotate(90deg);
  background: rgb(105, 105, 105);
  transition: all .3s ease-in-out;
}

.accordion-container .accordion-title::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 25px;
  width: 15px;
  height: 2px;
  background: rgb(112, 112, 112);
  transition: all .10s ease-in-out;
}

.accordion-container .accordion-title.open::before {
  transform: rotate(180deg);
}

.accordion-container .accordion-title.open::after {
  opacity: 0;
}

.accordion-content p {
  font-size: 16px;
}

.accordion-content {
  padding-left: 2.3125em;
  font-size: 14px;
  border: none;
  margin-top: 30px;
}

.p-faq {
  font-size: 14px;
}

#rotate {
  transform: rotate(90deg);
  right: -83px;
  bottom: 50%;
  position: fixed;
}

.enquiry-now-btn {
  padding: 0px 34px;
  font-size: 19px;
  font-weight: 500;
  background-color: #000;
  color: #fff;
  border-radius: 2px;
  text-transform: uppercase;
}

/* .enquiry-now{
 position: absolute; 
} */
.modal-content {
  background-color: #000 !important;
  width: 80% !important;
  padding: 20px;
}

.popup-text h4 {
  color: #ffffff !important;
}

.popup-text p {
  color: #ffffff !important;
}

.modal-dialog-centered {
  justify-content: center;
}

.btn-close {
  position: absolute;
  right: 20px !important;
  -webkit-filter: invert(1);
  /* background: transparent url('./asset/img/close.png') center/1em auto no-repeat !important; */
}

.modal-header {
  border-bottom: none !important;
}

.franchise-submit-btn {
  background-color: #818181;
  opacity: 0.9;

  color: white;
  width: 100%;
  padding: 10px;
  border: none;
  transition: all .4s ease-in-out;

}

.border-fra {
  border: none;
}

.franchis-btn {
  background-color: #2B3DDE;
  padding: 7px 40px;
  border: none;
  color: #fff;
  border-radius: 20px;
  font-size: 17px;
  transition: all .4s ease-in-out;
}

.franchis-btn:hover {
  background-color: #000000;
  padding: 7px 40px;
  border: none;
  color: #fff;
  border-radius: 20px;
  font-size: 17px;
}

.icon-pos {
  margin-left: 10px;
}

.cat-name {
  color: #0170B9;
}

.blog_error_msg {
  color: red;
  text-align: center;
  font-size: 22px;
  margin-top: 30px;
}

@media only screen and (max-width: 700px) {

  h3,
  .font-custom h1 {
    font-size: 2rem !important;
    font-weight: 800 !important;
  }

  .mt-150 {
    margin-top: 90px !important;
  }

  .banner-desc {
    color: #bebebe;
    font-size: 1.25rem !important;
  }

}



/* News css */
#results-driven {
  text-align: center;
  text-transform: uppercase;
  padding: 100px 0px;
  background: #000;
}

#results-driven h1 {
  font-size: 55px;
  color: #fff;
  font-weight: 700;
}

#results-driven h3 {
  font-size: 26px;
  color: #ccc;
  font-weight: 400;
}

.full-growthcontent a {
  text-transform: uppercase;
}

.full-growthcontent p {
  font-size: 16px;
  text-align: justify;
  font-weight: 400;
  line-height: 28px;
  color: #677788;
  margin-bottom: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
}

.full-growthcontent h6 {
  font-size: 22px;
  text-transform: capitalize;
  font-weight: 600;
  color: #433f34;
  margin-bottom: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.full-growthcontent img {
  height: 50px;
}




/* SM breakpoint */
@media (min-width: 576px) {

  /* CUSTOM WIDTHS */
  .w-sm-50 {
    width: 50% !important;
  }
}

/* MD breakpoint*/
@media (min-width: 768px) {

  /* CUSTOM WIDTHS */
  .w-md-50 {
    width: 50% !important;
  }
}

/* LG breakpoint */
@media (min-width: 992px) {

  /* CUSTOM WIDTHS */
  .w-lg-50 {
    width: 50% !important;
  }
}

.map_img {
  width: 100%;
}

.locat_list-hide {
  height: 0;
  overflow: hidden;
}


@media screen and (max-width:700px) {

  .social-banner,
  .about-sec-bg {
    padding-top: 5rem;
  }

  #map-container {
    width: 100% !important;
    margin: 0 auto;
  }
}