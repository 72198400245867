.footer-digidzn {
    background-color: black;
}

.footer-area-1 span {
    color: white;
    font-size: 15px;
}

.footer-area-1 link {
    margin-top: 30px;
}

.link-mt {
    margin-top: 20px;
}

.mt-10 {
    margin-top: 10%;
}

.footer-links {
    color: #fff;
    text-decoration: none;
    margin-bottom: 10px;
    font-size: 15px;
}

.footer-menu li {
    margin-bottom: 10px;
}

.alignment-foot-logo {
    display: flex;
    margin-left: -9px;
    justify-content: flex-start;
    align-items: center !important;
}

.footer-links-heading {
    font-size: 16px;
    color: #fff;
}

.footer-menu {
    list-style: none;
    margin-left: 40px;
}

ul.inline-icon li {
    display: inline;
    padding: 8px;

    font-size: 1.5rem;
    color: #9AA0A7;
}

.footer-icon {
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.footer-bottom-text {
    padding: 40px 0 20px 30px;
}

.footer-bottom-text p {
    font-size: 13px;
    color: #929292;
}

.footer-icon-links {
    color: #9AA0A7;
}

.mr-25 {
    margin-left: 35px;
}

.bold-text {
    font-weight: 600;
}

.transparent,
.accordion-button {
    background-color: transparent !important;
}

.accordion-button:not(.collapsed) {
    background-color: transparent;
    border: none;
    box-shadow: none;
}

.border-nones {
    border: none;
}

.transparent h2 .accordion-button {
    color: #fff;
    padding: 1.25rem 0;
    font-weight: 700;
    font-size: 1.125rem;
}

.footer-digidzn .accordion-button::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 0.625rem;
    height: 1rem;
    margin: auto 0;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='11' height='18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.504 8.928L2.004.412 0 2.42l6.495 6.508L0 15.436l2.004 2.008 8.5-8.516z' fill='%23fff'/%3E%3C/svg%3E") !important;
    background-position: 100%;
    background-size: 100%;
    background-repeat: no-repeat;
    -webkit-transition: -webkit-transform .2s ease-in-out;
    transition: -webkit-transform .2s ease-in-out;
    transition: transform .2s ease-in-out;
    transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
}

.footer-digidzn .accordion-button:not(.collapsed)::after {
    transform: rotate(90deg);
}

.accordion-button:focus {
    border: none;
    box-shadow: none;
}

.footer-digidzn .border-2 {
    border-bottom: 0.25rem solid #fff;
}

.footer-digidzn .line-height-2 {
    line-height: 2;
}

@media only screen and (max-width: 960px) {
    .footer-menu {
        margin-left: 0px;
    }

    .mob-dis-col {
        display: none;
    }

    .footer-bottom-text {
        padding: 11px 0 12px 30px;
    }
}


@media screen and (max-width:480px) {
    .footer_about {
        flex-direction: column;
    }

    .footer_about .text-white.mr-25 {
        margin-left: 0px !important;
        margin-top: 15px;
    }
}


.whatsapp {
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 99;
}

.whatsapp a {
    padding: 10px 15px;
    color: #fff;
    background-color: #25D366;
    border-radius: 50px;
    text-decoration: none;
    width: 50px;
    height: 50px;
    transition: all .5s;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 15%);
}

.whatsapp a span {
    font-size: 22px;
}

.whatsapp a:hover {
    transition: all .5s;
    color: #fff;
    background-color: #13a448;
}