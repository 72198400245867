body{
    font-family: "Poppins",Sans-serif;
}
.blog-size{
    width: 80%;
    
}
.card-blog{
    height: 560px;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 15%);
    border: none;
}
.wid-blog{
    width: 63px;
    height: 63px;
    object-fit: cover;
    position: relative;
    margin-top: -60px;
    /* border: 1px solid #999999; */
}
.catgory-blog{
    position: absolute ;
    right: 0;
    padding: 0.6em 1.2em;
    background: #000000;
    color: #fff;
    font-size: 12px;
    margin: 20px;
    border-radius: 999px;
    /* justify-content: end; */
}
.blog-title{
    color: #141414;
    font-family: "Poppins",Sans-serif;
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
}
.footer-list-blog{
    display: inline;
    padding: 10px;
    padding: 10px 15px;
    color: #656565;
    font-family: "Poppins",Sans-serif;
    font-size: 12px;
}
.blogpost-date {
    writing-mode: vertical-rl;
    transform: rotate(360deg);
    margin: -270px -7px 0 0px;
    padding: 30px 5px 30px 5px;
    /* align-content: flex-end; */
    background-color: #000;
    animation-name: fadeInLeft;
    transition: background 2s,border 2s,border-radius 2s,box-shadow 2s;
    animation-duration: 2.25s;
    /* position: relative; */
}
.blogpost-date h2{
    color: #FFF;
    font-family: "Poppins",Sans-serif;
    font-size: 20px;
    /* padding: 20px; */
    font-weight: 500;
    line-height: 1.4em;
}
.blogpost-date-ver{
    display: flex;
    /* flex-direction:column-reverse; */
    justify-content: end;
    align-items: flex-end;
}

.read-more-txt{
    color: #23A455;
    font-family: "Poppins",Sans-serif;
    font-weight: 500;
    text-decoration: none;
}
div{
    font-family: "Poppins",Sans-serif;
 }
.blog-img{
    height: 230px;
    object-fit: cover;
    width: 100%;
    border:none!important;
}
.icon1 {
    font-size: 26px;
}
.icon2 {
    font-size: 30px;
}
.icon3 {
    font-size: 29px;
}
.icon-div{
    margin-top: 30px;
}
.icon-s {
    font-size: 22px;
}
.icon-div {
    display: flex!important;
    align-items: center!important;
    justify-content: center!important;
    flex-wrap: wrap!important;
}
span.icon-txt1 {
    font-size: 14px;
    font-weight: 600;
    margin-left: 10px;
}
.icon-txt2 {
    font-size: 14px;
    font-weight: 600;
    margin-left: 10px;
}
span.icon-txt3 {
    font-size: 14px;
    font-weight: 600;
    margin-left: 10px;
}
.icon2{
    margin-left: 20px;
}
.icon3{
    margin-left: 20px;
    font-size: 18px;
}
.social-icon-div {
    text-align: center;
    margin-top: 20px;
    /* display: flex; */
    justify-self: center;
}
.icon-s{
    width: 25px;
}
h4.blog_title {
    font-size: 26px;
    font-weight: 400;
    color: #000;
}
.comment-h {
    font-size: 24px;
    margin-top: 30px;
}
.comment-ip{
    border: none;
    border: 1px solid #ccc;
    margin-top: 20px;
}
.input-b{
    border: none;
    border: 1px solid #ccc;
}
input[type="checkbox"] {
    width: 12px;
}
.checkDiv {
    display: flex;
    align-items: center;
    margin-top: 18px;
}
span.check-txt {
    font-size: 14px;
    margin-left: 20px;
}
button.post-btn {
    border: none;
    background-color: #ee2;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 10px;
    margin-top: 20px;
}
.recent-post-div {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 20px;
}





.comment-profile {
    display: flex;
    align-items: center;
}
.c-name{
    font-size: 16px;
    color: #0170B9;
    font-weight: 600;
}
h4.c-date {
    font-size: 14px;
    color: #0170B9;
}
.details {
    margin-left: 16px;
    margin-top: 6px;
}
.comment_list {
    margin-top: 40px;
}
p.content-p {
    margin-top: 13px;
}

input.search-ip {
    border: none;
    border: 1px solid #666;
    border-radius: 5px;
}
.pagination-div {
    text-align: center;
    margin: 35px;
}
button.preview-btn {
    border: none;
    margin: 10px;
    background-color: #fff;
}
button.next-btn {
    border: none;
    background-color: #fff;
    margin: 10px;
}
.searchBtn{
    position: absolute;
    margin-top: 13px;
    font-size: 20px;
    margin-left: -33px;
    color: #666;
    cursor: pointer;
}
.searchBgList {
    background-color: #fff;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 80px;
}
.searchVal {
    background-color: #eee;
    height: 115px;
    /* align-items: center; */
    /* display: flex; */
    padding-left: 120px;
}
.search-txt {
    color: #0170B9;
    font-size: 30px;
    font-weight: normal !important;
}
.top-space {
    margin-top: -115px;
}
.blog_search_title {
    font-size: 25px;
    margin-top: 30px;
}
.blog_search_des {
    font-size: 14px;
    margin-bottom: 30px;
}
.txt-size {
    font-size: 14px;
}
.reply-txt {
    margin-left: 46px;
    font-size: 14px;
}
.rp-txt{
    margin-left: 46px;
    font-size: 14px;
    color: #ccc;
}
@media only screen and (max-width: 768px) {
    .top-space {
        margin-top: 0px;
    }
}
@media only screen and (max-width: 1024px) {
 .card-blog{
     height: auto;
 }
 .searchBgList {
    background-color: #fff;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 80px;
}
.searchVal {
    background-color: #eee;
    height: 115px;
    /* align-items: center; */
    /* display: flex; */
    padding-left: 8px;
}
 input[type="checkbox"] {
    width: 24px;
}
.icon-div {
    display: flex!important;
    align-items: center!important;
    justify-content: center!important;
    flex-wrap: wrap!important;
    flex-direction: column;
}
}

.no-img-found {
    text-align: center;
    color: #666;
    font-size: 25px;
    background-color: #eee;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.blog-content {
    height: 100px;
    overflow-y: hidden;
}

.blog-view-img {
    width: 100%;
    height: auto;
}
.no-img-div {
    width: 100%;
    height: 197px;
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-size: 19px;
    color: #999;
}

/* loader  */
.sloader{
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
    justify-content: space-evenly;
    position: fixed;
    width: 100%;
}
.blog-view-active{
    border-bottom: 2px solid #3550CE;
}
sup img {
    width: 100% !important;
    height: auto !important;
}
.img-size1 p img {
    width: 100% !important;
    height: auto !important;
}
.img-size1 p{
    font-size: 16px;
}
.cmd-txt {
    color: #0170B9;
    margin-bottom: 20px;
    display: inline-block;
    font-size: 20px;
    margin-top: 10px;
}
.blog-imgg {
    margin-top: 40px;
    width: 100%;
}
.bcat-name{
    color: #000;
    margin-top: 30px;
}
.blog-des{
    height: 300px;
    overflow: hidden;
}
.readmore-div{
    margin-top: 30px;
    margin-bottom: 30px;
}
.blog-des-search {
    height: 45px;
    overflow: hidden;
}
.explore-container{
    background-color: #fff;
}