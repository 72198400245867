.about-sec-img{
  background-color: black;
}

.box {
  margin: 10px;
}

.grid {
  background-color: #000;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  
}

.grid > div {
  margin-bottom: 100px;
  flex-basis: calc(35% - 90px);
}
.text {
  color: white;
  /* font-size: 20px; */
  position: absolute;
  top: 50%;
  left: 50%;
  /* padding: 0px; */
  width: 80%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: left;
}
.text h1{
  color:#fff;
}
.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.178);
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: .5s ease;
}
.about-sec-img:hover .overlay {
  height: 100%;
}
.boxy {
  width: 200px;
  background: green;
  /* padding: 50px; */
  margin-left: auto;
  margin-right: auto;
}
.text p{
font-size: 15px; 
font-weight: 700;
 margin-top: 20px;
}
.text h1{
  font-size: 25px;  
  font-weight: 700;
}
.about-sec-img img{
  height: 400px;
  object-fit: cover;
  object-position: top;
  filter: grayscale(1);
}
@media only screen and (max-width: 770px) {
  .grid > div {
    margin-bottom: 100px;
    flex-basis: calc(60% - 90px);
  }
}
@media only screen and (max-width: 500px) {
  .grid > div {
    margin-bottom: 100px;
    flex-basis: auto;
  }
}